'use strict';

import React from 'react';
import { Helmet } from 'react-helmet';
import styled from 'styled-components';

import { graphql } from 'gatsby';

import Layout from '../components/Layout';

const IndexContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;

    height: 100%;

    max-width: 960px;
    margin: 0 auto;
`;

const Photo = styled.img`
    width: 12rem;
    height: auto;
    border-radius: 50%;

    align-self: center;

    margin-bottom: 2rem;
`;

export default ({ data }) => (
    <Layout currentPage="index" data={data}>
        <Helmet>
            <meta name="description" content={data.site.siteMetadata.tagline} />
        </Helmet>

        <IndexContainer>
            <Photo
                src={require('../assets/photo-rishit-kedia.png')}
                alt={data.site.siteMetadata.name}
            />

            <h1>{data.site.siteMetadata.name}</h1>
        </IndexContainer>
    </Layout>
);

export const query = graphql`
    query IndexQuery {
        site {
            siteMetadata {
                name
            }

            ...FooterFragment
        }
    }
`;
